export const BASE_URL = "";
export const CANVAS_TRIGGER_TYPES = {
  ZOOM_IN: "ZOOM_IN",
  ZOOM_OUT: "ZOOM_OUT",
  FULLSCREEN: "FULLSCREEN",
  FRAME_ZOOM: "FRAME_ZOOM",
};
export const canvasDim = {
  width: 746,
  height: 467,
};
/**
 * give access in public directory for 3d scripts
 */
window.CANVAS_TRIGGER_TYPES = CANVAS_TRIGGER_TYPES;
