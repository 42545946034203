import * as React from "react";
import "./../KitBuilder.scss";

// reusable components import
import Button from "../../../components/Button/Button.js";
import PlantWidget from "../../../components/PlantWidget/PlantWidget";

export default function KitRoomRetry(props) {
  function submitKit(kitValue) {
    props.setPlantKit(kitValue);
    props.history.push("/kitbuilder/content");
  }

  return (
    <div className="step-main-container">
      <div className="step-content kit-room-content">
        <h1 className="step-title">
          Your room size cannot accommodate the {props.plantKit} plant kit.{" "}
          <br />
          We recommend you choose one of the kits below.
        </h1>
        <div className="kit-container">
          <PlantWidget number={6} onClick={submitKit} />
          <PlantWidget number={8} onClick={submitKit} />
        </div>
        <Button
          variant="outline"
          text="Proceed with the 8 plant kit"
          onClick={() => props.history.push("/kitbuilder/content")}
        />
      </div>
    </div>
  );
}
