import React, { Component } from "react";
import {
  Stage,
  Layer,
  Rect,
  Text,
  Group,
  Image,
  Line,
  Shape,
} from "react-konva";

import rotateSvg from "./../../../../assets/svg/rotate.svg";
import CanvasGrid from "./CanvasGrid";

// custom component that will handle loading image from url
// you may add more logic here to handle "loading" state
// or if loading is failed
// VERY IMPORTANT NOTES:
// at first we will set image state to null
// and then we will set it to native image instance when it is loaded
class URLImage extends React.Component {
  state = {
    image: null,
  };
  componentDidMount() {
    this.loadImage();
  }
  componentDidUpdate(oldProps) {
    if (oldProps.src !== this.props.src) {
      this.loadImage();
    }
  }
  componentWillUnmount() {
    this.image.removeEventListener("load", this.handleLoad);
  }
  loadImage() {
    // save to "this" to remove "load" handler on unmount
    this.image = new window.Image();
    this.image.src = this.props.src;
    this.image.addEventListener("load", this.handleLoad);
  }
  handleLoad = () => {
    // after setState react-konva will update canvas and redraw the layer
    // because "image" property is changed
    this.setState({
      image: this.image,
    });
    // if you keep same image object during source updates
    // you will have to update layer manually:
    // this.imageNode.getLayer().batchDraw();
  };
  render() {
    return (
      <Image
        x={this.props.x}
        y={this.props.y}
        image={this.state.image}
        ref={(node) => {
          this.imageNode = node;
        }}
      />
    );
  }
}

const gridCellDimension = 120;
const gridInnerCellDimension = 20;

class TrayCanvas extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  timer = 0;

  onMouseOver = () => {
    clearTimeout(this.timer);
    if (!this.state.showBtn) {
      this.setState({
        showBtn: true,
      });
    }
  };

  onMouseOut = () => {
    clearTimeout(this.timer);
    if (this.state.showBtn) {
      this.timer = setTimeout(() => {
        this.setState({
          showBtn: false,
        });
      }, 200);
    }
  };

  render() {
    // text?: string;
    // fontFamily?: string;
    // fontSize?: number;
    // fontStyle?: string;
    // fontVariant?: string;
    // textDecoration?: string;
    // align?: string;
    // verticalAlign?: string;
    // padding?: number;
    // lineHeight?: number;
    // wrap?: string;
    // ellipsis?: boolean;

    const btnWidth = 89;
    const btnHeight = 73;

    let btnY = 0;
    let btnX = 0;

    let trayY = 0;

    if (this.props.variant === "right") {
      btnY = this.props.y + this.props.bodyHeight - btnHeight;
      btnX = this.props.x + this.props.bodyWidth + 10;
      trayY = this.props.y;
    } else {
      btnY = this.props.y;
      btnX = this.props.x;

      trayY = this.props.y + btnHeight + 10;
    }
    return (
      <Group draggable>
        {this.state.showBtn && (
          <Group
            onMouseOut={this.onMouseOut}
            onMouseOver={this.onMouseOver}
            onClick={this.props.rotate ? this.props.rotate : () => {}}
          >
            <Rect
              width={btnWidth}
              height={btnHeight}
              x={btnX}
              y={btnY}
              fill="white"
              strokeEnabled={true}
              strokeScaleEnabled={true}
              stroke="#707070"
              strokeWidth={1}
            />
            <Text
              x={btnX}
              y={btnY}
              width={btnWidth}
              fill="#487629"
              text="Rotate"
              fontSize={12}
              lineHeight={2}
              fontFamily="Lato"
              align="center"
            />
            <URLImage src={rotateSvg} x={btnX + 30} y={btnY + 34} />
          </Group>
        )}
        <Group onMouseOut={this.onMouseOut} onMouseOver={this.onMouseOver}>
          <Rect
            width={this.props.bodyWidth}
            height={this.props.bodyHeight}
            x={this.props.x}
            y={trayY}
            fill="white"
            strokeEnabled={true}
            strokeScaleEnabled={true}
            stroke="#707070"
            strokeWidth={1}
          />
          <Text
            x={this.props.x}
            y={trayY}
            width={this.props.bodyWidth}
            height={this.props.bodyHeight}
            fillEnabled={true}
            fill="#487629"
            text={this.props.title}
            fontSize={21}
            lineHeight={20}
            fontFamily="Lato"
            align="center"
            verticalAlign="center"
          />
        </Group>
      </Group>
    );
  }
}

export default class Room2dCanvas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mouseEvent: {},
      trays: [
        {
          bodyWidth: 660,
          bodyHeight: 451,
          x: 100,
          y: 200,
          title: "TRAY A",
          variant: "right",
        },
        {
          bodyWidth: 400,
          bodyHeight: 451,
          title: "TRAY B",
          x: 1000,
          y: 200,
        },
      ],
    };
    this.canvasRef = React.createRef();
  }
  zoom = 1;
  newScale = undefined;

  onZoom = (zoomDir) => {};
  componentDidMount = () => {
    let stage = this.canvasRef.current;
    if (stage) {
      let scaleBy = 1.25;
      stage.on("wheel", (e) => {
        let stage = this.canvasRef.current;
        e.evt.preventDefault();
        let oldScale = stage.scaleX();

       

        let pointer = stage.getPointerPosition();

        let mousePointTo = {
          x: (pointer.x - stage.x()) / oldScale,
          y: (pointer.y - stage.y()) / oldScale,
        };

        let newScale =
          e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

          if(oldScale <= 0.4 && newScale <= oldScale) {
            return;
          }

        this.setState({
          eventMouseKey: Date.now(),
          mouseEvent: {
            pageX: e.evt.pageX,
            pageY: e.evt.pageY,
            type: e.evt.type,
            deltaY: e.evt.deltaY,
            preventDefault: e.evt.preventDefault,
          },
        });

        stage.scale({ x: newScale, y: newScale });

        let newPos = {
          x: pointer.x - mousePointTo.x * newScale,
          y: pointer.y - mousePointTo.y * newScale,
        };
        this.newScale = newScale;

        console.log(this.newScale)

        stage.position(newPos);
        stage.batchDraw();
      });
    }
  };
  componentDidUpdate = (prevProps) => {
    if (prevProps.zoomKey !== this.props.zoomKey) {
      this.onZoom(this.props.zoomDir);
    }
  };

  onTrayRotation = (index) => {
    this.setState({
      trays: this.state.trays.map((_item, _index) => {
        if (index === _index) {
          return {
            ..._item,
            bodyWidth: _item.bodyHeight,
            bodyHeight: _item.bodyWidth,
          };
        }
        return _item;
      }),
    });
  };
  render() {
    return (
      <div
        style={{
          width: this.props.canvasSize.width + "px",
          height: this.props.canvasSize.height + "px",
        }}
        className="position-relative"
      >
        <CanvasGrid
          canvasSize={this.props.canvasSize}
          eventMouseKey={this.state.eventMouseKey}
          mouseEvent={this.state.mouseEvent}
        />
        <div className="absolute-top left">
          <Stage
            style={{
              border: "1px solid black",
            }}
            ref={this.canvasRef}
            width={this.props.canvasSize.width}
            height={this.props.canvasSize.height}
          >
            <Layer
              // draggable
              width={this.props.canvasSize.width}
              height={this.props.canvasSize.height}
            >
              {/* <Group draggable>
                <Text x={24} y={0} text="Draggable" />
                <URLImage src={testImg} x={150} />
              </Group> */}
              {this.state.trays.map((item, index) => (
                <TrayCanvas
                  bodyWidth={item.bodyWidth}
                  bodyHeight={item.bodyHeight}
                  x={item.x}
                  y={item.y}
                  variant={item.bodyWidth > item.bodyHeight ? "right" : ""}
                  title={item.title}
                  rotate={() => this.onTrayRotation(index)}
                />
              ))}
            </Layer>
          </Stage>
        </div>
      </div>
    );
  }
}
