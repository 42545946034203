// @flow
import * as React from "react";

import "./QuestionMarkWidget.scss";

export function QuestionMarkWidget(props) {
  return (
    <div
      className={
        "question-mark-wrapper" + (props.onClick ? " cursor-pointer" : "")
      }
      onClick={(event) => {
        if (props.onClick) {
          event.stopPropagation();
          props.onClick(event);
        }
      }}
    >
      <span>?</span>
    </div>
  );
}
