import React from "react";
// reusable components import
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";

export default function KitRoomFormStepTwo(props) {
  function submitRoomSize() {
    if (
      props.roomSize.roomWidth > 1000 &&
      props.roomSize.roomLength > 1000 &&
      props.roomSize.roomHeight > 1000
    ) {
      props.history.push("/kitbuilder/room/retry");
    } else {
      props.history.push("/kitbuilder/content");
    }
  }

  return (
    <div className="step-main-container">
      <div className="step-content kit-room-content">
        <h1 className="step-title">Let’s set up your growing zones.</h1>
        <Input
          type="number"
          placeholder="Number of zones"
          value={props.roomSize.zoneNumber || ""}
          onChange={(event) =>
            props.setRoomSize({
              ...props.roomSize,
              zoneNumber: event.target.value,
            })
          }
        />
        <Input
          type="number"
          placeholder="Zone width"
          value={props.roomSize.zoneWidth || ""}
          onChange={(event) =>
            props.setRoomSize({
              ...props.roomSize,
              zoneWidth: event.target.value,
            })
          }
        />
        <Input
          type="number"
          placeholder="Zone length"
          value={props.roomSize.zoneLength || ""}
          onChange={(event) =>
            props.setRoomSize({
              ...props.roomSize,
              zoneLength: event.target.value,
            })
          }
        />
        <Input
          type="number"
          placeholder="Number of plants per zone"
          value={props.roomSize.plantsPerZone || ""}
          onChange={(event) =>
            props.setRoomSize({
              ...props.roomSize,
              plantsPerZone: event.target.value,
            })
          }
        />

        <Button text="Next" onClick={() => submitRoomSize()} />
      </div>
    </div>
  );
}
