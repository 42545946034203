import * as React from "react";
import "./Input.scss";

/**
 * @param {object} props Component props
 * @param {string | undefined} props.variant - "outline", "min"
 * @param {string | undefined} props.className
 * @param {string | undefined} props.placeholder
 * @param {string | undefined} props.type
 * @param {number | undefined} props.min
 * @param {number | undefined} props.max
 * @param {function} props.onEnter
 * @param {function} props.onChange
 */
export default function Input(props) {
  return (
    <input
      onKeyUp={(event) => {
        if (
          (event.keyCode === 13 || event.key === 13) &&
          typeof props.onEnter === "function"
        ) {
          props.onEnter(event);
        }
      }}
      onChange={(event) => {
        if (typeof props.onChange === "function") {
          props.onChange(event);
        }
      }}
      className={
        "custom-input variant-" +
        (props.variant || "") +
        (props.className ? " " + props.className : "")
      }
      type={props.type || "text"}
      min={props.min}
      max={props.max}
      value={props.value || ""}
      placeholder={props.placeholder}
    ></input>
  );
}
