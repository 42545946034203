import * as React from "react";
import Input from "../Input/Input";
import "./CartBuildLine.scss";

/**
 * @param {object} props Component props
 * @param {ReactElement | undefined} props.iconLeft
 * @param {ReactElement | undefined} props.inputLeftIcon
 * @param {string | number} props.price
 * @param {string} props.label
 * @param {string | number} props.value
 * @param {function} props.onChange
 */
export default function CartBuildLine(props) {
  return (
    <div className="cart-build-line">
      <div className="cart-build-line-inner">
        {props.iconLeft && <div className="icon-wrapper">{props.iconLeft}</div>}
        <div className="label-wrapper">
          <span>{props.label}</span>
        </div>
        <div className="input-wrapper">
          <Input
            value={props.value}
            onChange={props.onChange}
            type="number"
            variant={"min"}
          />
        </div>
        <div className="price-wrapper">
          <div className="price-label">Price/Unit</div>
          <div className="price-amount">${props.price}</div>
        </div>
      </div>
    </div>
  );
}
