import React from "react";

import { Switch, Route, Redirect } from "react-router-dom";
import Layout from "./layout/Layout";
import BasePage from "./Basepage";
import AuthPage from "./auth/AuthPage";

export default function Routes() {
  const isAuth = true;

  return !isAuth ? (
    <Switch>
      <Route path="/auth" component={AuthPage} />
      <Route path="/" render={() => <Redirect to="/auth" />} />
    </Switch>
  ) : (
    <Layout>
      <BasePage />
    </Layout>
  );
}
