const initialState = {
  loader: {},
  pageConfig: {}
};
const actionTypes = {
  RESET_CONFIG: "RESET_CONFIG",
  SET_LOADER: "SET_LOADER",
};
export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.RESET_CONFIG: {
      return initialState;
    }
    case actionTypes.SET_LOADER: {
      const loader = state.loader;
      if (!payload.loader) {
        delete loader[payload.loaderKey];
      } else {
        loader[payload.loaderKey] = payload.loader;
      }
      return {
        ...state,
        loader: {
          ...loader,
        },
      };
    }
    default:
      return state;
  }
};
export const actions = {
  resetConfig: (payload) => ({ payload, type: actionTypes.RESET_CONFIG }),
  setLoader: (payload) => ({ payload, type: actionTypes.SET_LOADER }),
};

export const selectors = {
  getLoader: (layout) => {
    const firstKey = Object.keys(layout.loader).length
      ? Object.keys(layout.loader)[0]
      : undefined;
    if (!firstKey) {
      return {};
    }
    return layout.loader[firstKey] || {};
  },
};
