import React, { Component } from "react";
import { Layer, Shape, Stage } from "react-konva";

export default class CanvasGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.canvasRef = React.createRef();
  }

  mouse = {
    x: 0,
    y: 0,
    button: false,
    wheel: 0,
    lastX: 0,
    lastY: 0,
    drag: false,
  };
  panZoom = {
    x: 0,
    y: 0,
    scale: 1,
    apply(ctx) {
      if (ctx) {
        ctx.setTransform(this.scale, 0, 0, this.scale, this.x, this.y);
      }
    },
    scaleAt(x, y, sc) {
      // x & y are screen coords, not world
      this.scale *= sc;
      this.x = x - (x - this.x) * sc;
      this.y = y - (y - this.y) * sc;
    },
  };

  componentDidMount = () => {
    requestAnimationFrame(this.update);
    // ["mousedown", "mouseup", "mousemove", "wheel"].forEach((name) =>
    //   document.addEventListener(name, this.mouseEvents)
    // );
  };
  mouseEvents = (e) => {
    console.log(e);
    const ctx = this.getCanvasCtx();
    const canvas = this.getCanvasNode();
    if (!ctx) {
      return;
    }
    const bounds = canvas.getBoundingClientRect();
    let mouse = this.mouse;
    mouse.x = e.pageX - bounds.left - 0; //scrollX;
    mouse.y = e.pageY - bounds.top - 0; //scrollY;
    mouse.button =
      e.type === "mousedown"
        ? true
        : e.type === "mouseup"
        ? false
        : mouse.button;
    if (e.type === "wheel") {
      mouse.wheel += -e.deltaY;
      //   e.preventDefault && e.preventDefault();
    }
    this.mouse = mouse;
  };
  getCanvasCtx = () => {
    if (this.canvasRef.current) {
      const canvasNode = this.canvasRef.current.content.querySelector("canvas");
      if (canvasNode) {
        const ctx = canvasNode.getContext("2d");
        return ctx;
      }
    }
    return null;
  };
  getCanvasNode = () => {
    if (this.canvasRef.current) {
      const canvasNode = this.canvasRef.current.content.querySelector("canvas");
      if (canvasNode) {
        return canvasNode;
      }
    }
    return null;
  };
  drawGrid = () => {
    const ctx = this.getCanvasCtx();
    const canvas = this.getCanvasNode();
    if (!ctx) {
      return;
    }
    let panZoom = this.panZoom;
    var w = canvas.width;
    var h = canvas.height;

    const scale = 1 / panZoom.scale;
    // var gridScale = 2 ** (Math.log2(128 * scale) | 0);
    var gridScale = 100;
    var size = Math.max(w, h) * scale + gridScale * 2;
    var x = (((-panZoom.x * scale - gridScale) / gridScale) | 0) * gridScale;
    var y = (((-panZoom.y * scale - gridScale) / gridScale) | 0) * gridScale;
    panZoom.apply(ctx);
    ctx.lineWidth = 1;
    ctx.strokeStyle = "black";

    ctx.beginPath();
    ctx.strokeStyle = "#aaaaaa";
    ctx.lineWidth = 2;
    for (let i = 0; i < size; i += gridScale) {
      const line1 = {
        moveToX: x + i,
        moveToY: y,
        lineToX: x + i,
        lineToY: y + size,
      };
      const line2 = {
        moveToX: x,
        moveToY: y + i,
        lineToX: x + size,
        lineToY: y + i,
      };
      ctx.moveTo(line1.moveToX, line1.moveToY);
      ctx.lineTo(line1.lineToX, line1.lineToY);
      ctx.moveTo(line2.moveToX, line2.moveToY);
      ctx.lineTo(line2.lineToX, line2.lineToY);
    }
    ctx.stroke();


    ctx.lineWidth = 1;
    for (let i = 0; i < size; i += gridScale) {
      
      const line1 = {
        moveToX: x + i,
        moveToY: y,
        lineToX: x + i,
        lineToY: y + size,
      };
      const line2 = {
        moveToX: x,
        moveToY: y + i,
        lineToX: x + size,
        lineToY: y + i,
      };

      
      let distance = line1.lineToX + gridScale/6;
      for(let ii = 0; ii < 5; ii++) {
        ctx.moveTo(distance, line1.moveToY);
        ctx.lineTo(distance, line1.lineToY);
        distance += gridScale/6;
      }

      distance = line2.lineToY + gridScale/6;
      for(let ii = 0; ii < 5; ii++) {
        ctx.moveTo(line2.moveToX, distance);
        ctx.lineTo(line2.lineToX, distance);
        distance += gridScale/6;
      }
    }
    // ctx.setTransform(1, 0, 0, 1, 0, 0); // reset the transform so the lineWidth is 1
    ctx.stroke();

    this.panZoom = panZoom;
  };
  update = () => {
    const ctx = this.getCanvasCtx();
    const canvas = this.getCanvasNode();
    if (!ctx) {
      return;
    }
    let mouse = this.mouse;
    var w = canvas.width;
    var h = canvas.height;
    let panZoom = this.panZoom;

    ctx.setTransform(1, 0, 0, 1, 0, 0); // reset transform
    ctx.globalAlpha = 1; // reset alpha
    if (w !== window.innerWidth || h !== window.innerHeight) {
      w = canvas.width = window.innerWidth;
      h = canvas.height = window.innerHeight;
    } else {
      ctx.clearRect(0, 0, w, h);
    }
    if (mouse.wheel !== 0) {
      let scale = 1;
      scale = mouse.wheel < 0 ? 1 / 1.01 : 1.01;
      mouse.wheel *= 0.8;
      if (Math.abs(mouse.wheel) < 1) {
        mouse.wheel = 0;
      }
      panZoom.scaleAt(mouse.x, mouse.y, scale); //scale is the change in scale
    }
    if (mouse.button) {
      if (!mouse.drag) {
        mouse.lastX = mouse.x;
        mouse.lastY = mouse.y;
        mouse.drag = true;
      } else {
        panZoom.x += mouse.x - mouse.lastX;
        panZoom.y += mouse.y - mouse.lastY;
        mouse.lastX = mouse.x;
        mouse.lastY = mouse.y;
      }
    } else if (mouse.drag) {
      mouse.drag = false;
    }
    this.mouse = mouse;
    this.panZoom = panZoom;

    this.drawGrid();
    requestAnimationFrame(this.update);
  };
  //   componentWillUnmount = () => {
  //     ["mousedown", "mouseup", "mousemove", "wheel"].forEach((name) =>
  //       document.removeEventListener(name, this.mouseEvents)
  //     );
  //   };
  componentDidUpdate = (prevProps) => {
    if (prevProps.eventMouseKey !== this.props.eventMouseKey) {
      this.mouseEvents(this.props.mouseEvent);
    }
  };
  render() {
    return (
      <div className="absolute-top left">
        <Stage
          style={{
            border: "1px solid black",
          }}
          ref={this.canvasRef}
          width={this.props.canvasSize.width}
          height={this.props.canvasSize.height}
        >
          <Layer>
            {/* <Shape
              sceneFunc={(context, shape) => {
                // context.beginPath();
                // context.moveTo(20, 50);
                // context.lineTo(220, 80);
                // context.quadraticCurveTo(150, 100, 260, 170);
                // context.closePath();
                // // (!) Konva specific method, it is very important
                let ctx = content;
                let mouse = this.mouse;
                // var w = canvas.width;
                // var h = canvas.height;
                let panZoom = this.panZoom;

                ctx.setTransform(1, 0, 0, 1, 0, 0); // reset transform
                ctx.globalAlpha = 1; // reset alpha
                if (w !== window.innerWidth || h !== window.innerHeight) {
                  // w = canvas.width = window.innerWidth;
                  // h = canvas.height = window.innerHeight;
                } else {
                  ctx.clearRect(0, 0, w, h);
                }
                if (mouse.wheel !== 0) {
                  let scale = 1;
                  scale = mouse.wheel < 0 ? 1 / 1.01 : 1.01;
                  mouse.wheel *= 0.8;
                  if (Math.abs(mouse.wheel) < 1) {
                    mouse.wheel = 0;
                  }
                  panZoom.scaleAt(mouse.x, mouse.y, scale); //scale is the change in scale
                }
                if (mouse.button) {
                  if (!mouse.drag) {
                    mouse.lastX = mouse.x;
                    mouse.lastY = mouse.y;
                    mouse.drag = true;
                  } else {
                    panZoom.x += mouse.x - mouse.lastX;
                    panZoom.y += mouse.y - mouse.lastY;
                    mouse.lastX = mouse.x;
                    mouse.lastY = mouse.y;
                  }
                } else if (mouse.drag) {
                  mouse.drag = false;
                }
                this.mouse = mouse;
                this.panZoom = panZoom;

                this.drawGrid();
                requestAnimationFrame(this.update);
                context.fillStrokeShape(shape);
              }}
              fill="#00D2FF"
              stroke="black"
              strokeWidth={4}
            /> */}
          </Layer>
        </Stage>
      </div>
    );
  }
}
