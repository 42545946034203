import React from 'react';
// reusable components import
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";

export default function KitRoomForm(props) {
    function submitRoomSize() {
      if (
        props.roomSize.roomWidth > 1000 &&
        props.roomSize.roomLength > 1000 &&
        props.roomSize.roomHeight > 1000
      ) {
        props.history.push("/kitbuilder/room/retry");
      } else {
        props.history.push("/kitbuilder/room/form-2");
      }
    }
  
    return (
      <div className="step-main-container">
        <div className="step-content kit-room-content">
          <h3 className="step-subtitle">
            You selected the {props.plantKit} plant kit.
          </h3>
          <h1 className="step-title">How big is your room?</h1>
          <Input
            type="number"
            placeholder="Width"
            value={props.roomSize.roomWidth || ""}
            onChange={(event) =>
              props.setRoomSize({
                ...props.roomSize,
                roomWidth: event.target.value,
              })
            }
          />
          <Input
            type="number"
            placeholder="Length"
            value={props.roomSize.roomLength || ""}
            onChange={(event) =>
              props.setRoomSize({
                ...props.roomSize,
                roomLength: event.target.value,
              })
            }
          />
          <Input
            type="number"
            placeholder="Height"
            value={props.roomSize.roomHeight || ""}
            onChange={(event) =>
              props.setRoomSize({
                ...props.roomSize,
                roomHeight: event.target.value,
              })
            }
          />
          <Button text="Next" onClick={() => submitRoomSize()} />
        </div>
      </div>
    );
  }