// @flow
import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { NavigationMenu } from "../../layout/NavigationMenu/NavigationMenu";
import KitOptions from "./KitOptions/KitOptions";
import KitRoom from "./KitRoom/KitRoom";
import KitIrrigation from "./KitIrrigation";
import KitContent from "./KitContent/KitContent";
import WithNavigation from "../../components/WithNavigation/WithNavigation";

import "./KitBuilder.scss";

const STEPS = [
  { value: "Kit Options", label: "Kit Options", key: "KitBuilder_Kit Options" },
  {
    value: "Room Settings",
    label: "Room Settings",
    key: "KitBuilder_Room Settings",
  },
  { value: "Kit Content", label: "Kit Content", key: "KitBuilder_Kit Content" },
  { value: "Irrigation", label: "Irrigation", key: "KitBuilder_Irrigation" },
  { value: "Nutrients", label: "Nutrients", key: "KitBuilder_Nutrients" },
];

export function KitBuilder(props) {
  const [state, setState] = React.useState({
    plantNumber: 0,
    selectedIndex: 0,
    plantKit: 0,
    roomSize: {},
    plantContainer: {},
    irrigationItems: [
      {
        key: "Bubbler Matrix - irigation",
        subTitle: "Bubbler Matrix",
        number: 0,
      },
      {
        key: "Bubbler FloraCap - irigation",
        number: 0,
        subTitle: "Bubbler FloraCap",
        number: 0,
      },
  
      {
        key: "Bubbler 1/ - irigation",
        number: 0,
        subTitle: "Bubbler 1/4” Drippers and Shield",
        number: 0,
      },
  
      {
        key: "Bubbler 1/ - irigation",
        number: 0,
        subTitle: "Bubbler 1/4” Drippers",
        number: 0,
      },
  
      {
        key: "Drip Shields  - irigation",
        number: 0,
        subTitle: "Drip Shields and Micro Tube Drippers",
        number: 0,
      },
  
      {
        key: "Micro Tube  - irigation",
        number: 0,
        subTitle: "Micro Tube Drippers",
        number: 0,
      },
    ]
  });

  function setCurrentStep(stepValue) {
    setState({
      ...state,
      selectedIndex: STEPS.findIndex((item) => item.value === stepValue),
    });
  }
  function setPlantKit(plantKit) {
    setState({ ...state, plantKit });
  }

  function setPlantContainerValue(value) {
    setState({
      ...state,
      plantContainer: {
        ...state.plantContainer,
        value,
      },
    });
  }

  return (
    <div className="kitbuilder">
      <div className="kitbuilder-main-wrapper">
        <NavigationMenu steps={STEPS} selectedIndex={state.selectedIndex} />
        <Switch>
          <Route
            path="/kitbuilder/options"
            render={(props) => (
              <WithNavigation
                history={props.history}
                onChangeCurrentPath={(value) => setCurrentStep(value)}
                value="Kit Options"
              >
                <KitOptions
                  {...props}
                  state={state}
                  setState={setState}
                  setPlantKit={setPlantKit}
                />
              </WithNavigation>
            )}
          />
          <Route
            path="/kitbuilder/room"
            render={(props) => (
              <WithNavigation
                history={props.history}
                onChangeCurrentPath={(value) => setCurrentStep(value)}
                value="Room Settings"
              >
                <KitRoom
                  {...props}
                  roomSize={state.roomSize}
                  plantKit={state.plantKit}
                  setPlantKit={setPlantKit}
                  setRoomSize={(roomSize) => {
                    setState({ ...state, roomSize });
                  }}
                />
              </WithNavigation>
            )}
          />
          <Route
            path="/kitbuilder/irrigation"
            render={() => (
              <WithNavigation
                history={props.history}
                onChangeCurrentPath={(value) => setCurrentStep(value)}
                value="Irrigation"
              >
                <KitIrrigation {...props} irrigationItems={state.irrigationItems} setIrrigationItems={(irrigationItems) => {
                  setState({
                    ...state,
                    irrigationItems
                  })
                }} />
              </WithNavigation>
            )}
          />
          <Route
            path="/kitbuilder/content"
            render={() => (
              <WithNavigation
                history={props.history}
                onChangeCurrentPath={(value) => setCurrentStep(value)}
                value="Kit Content"
              >
                {" "}
                <KitContent
                  {...props}
                  setPlantContainerValue={setPlantContainerValue}
                  plantContainerValue={state.plantContainer.value}
                />{" "}
              </WithNavigation>
            )}
          />
          <Route
            path="/kitbuilder"
            render={() => <Redirect to="/kitbuilder/options" />}
          />
        </Switch>
      </div>
    </div>
  );
}
