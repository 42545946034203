// @flow
import * as React from "react";
import { ButtonGroup, Dropdown } from "react-bootstrap";

import { ReactComponent as CrtDownIcon } from "./../../../assets/svg/ctrl-down.svg";

import "./Dropdown.scss";

/**
 * @param {object} props Component props
 * @param {Array} props.items
 * @param {function} props.onChange
 * @param {Object[]} props.items
 * @param {object} props.items[].key
 * @param {object} props.items[].value
 * @param {object} props.items[].label
 * @param {object} props.selected
 * @param {object} props.selected.value
 * @param {object} props.selected.label
 */
export default function DropdownItem(props) {
  return (
    <Dropdown as={ButtonGroup}>
      <div
        className={`my-dropdown${props.className ? " " + props.className : ""}`}
      >
        <div className="my-dropdown-inner">
          <div className="dropdown-left">
            <span>{props.selected?.label || props.displayValue }</span>
          </div>
          <Dropdown.Toggle split variant="success" className="dropdown-right">
            <div className="dropdown-right-inner">
              <CrtDownIcon />
            </div>
          </Dropdown.Toggle>
        </div>
      </div>

      <Dropdown.Menu className="my-app-dropdown-options">
        {props.items &&
          props.items.map((item, index) => {
            return <Dropdown.Item
              onClick={() => {
                props.onChange && props.onChange(item, index);
              }}
              eventKey="1"
              key={`${item.key}-${index}-dropdown`}
            >
              {item.label}
            </Dropdown.Item>;
          })}
      </Dropdown.Menu>
    </Dropdown>
  );
}
