import React from "react";
import LayoutInit from "./LayoutInit";
import { useSelector } from "react-redux";

import LayoutSplashScreen from "./../components/LayoutSplashScreen/LayoutSplashScreen";
import Header from "./components/Header/Header";

export default function Layout({ children }) {
  const { loader } = useSelector(({ layoutService }) => ({
    loader: [],
  }));

  return (
    <>
      {loader.length > 0 && <LayoutSplashScreen />}
      <Header />
      <main className="d-flex flex-column flex-root">{children}</main>
      <LayoutInit />
    </>
  );
}
