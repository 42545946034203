// @flow
import * as React from "react";
import Gallery from "../../../components/ImageGridList/ImageGridList";
import Button from "./../../../components/Button/Button";

import Image1 from "./../../../../assets/image/test/gallery_test(1).png";
import Image2 from "./../../../../assets/image/test/gallery_test(2).png";
import Image3 from "./../../../../assets/image/test/gallery_test(3).png";
import Image4 from "./../../../../assets/image/test/gallery_test(4).png";
import Image5 from "./../../../../assets/image/test/gallery_test(5).png";
import Image6 from "./../../../../assets/image/test/gallery_test(6).png";
import Image7 from "./../../../../assets/image/test/gallery_test(7).png";
import Image8 from "./../../../../assets/image/test/gallery_test(8).png";
import Image9 from "./../../../../assets/image/test/gallery_test(9).png";

const IMAGES = [
  {
    key: "img1",
    src: Image1,
    alt: "After Rain (Jeshu John - designerspics.com)",
  },

  {
    key: "img1",
    src: Image2,
    alt: "After Rain (Jeshu John - designerspics.com)",
  },

  {
    key: "img1",
    src: Image3,
    alt: "After Rain (Jeshu John - designerspics.com)",
  },

  {
    key: "img1",
    src: Image4,
    alt: "After Rain (Jeshu John - designerspics.com)",
  },

  {
    key: "img1",
    src: Image5,
    alt: "After Rain (Jeshu John - designerspics.com)",
  },

  {
    key: "img1",
    src: Image6,
    alt: "After Rain (Jeshu John - designerspics.com)",
  },

  {
    key: "img1",
    src: Image7,
    alt: "After Rain (Jeshu John - designerspics.com)",
  },

  {
    key: "img1",
    src: Image8,
    alt: "After Rain (Jeshu John - designerspics.com)",
  },

  {
    key: "img1",
    src: Image9,
    alt: "After Rain (Jeshu John - designerspics.com)",
  },

  {
    key: "img1",
    src: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
    alt: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    key: "img12",
    src: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",

    alt: "Boats (Jeshu John - designerspics.com)",
  },
  {
    key: "img122",
    src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
  },
];

export default function KitRoomImport(props) {
  function loadMoreImages() {}
  return (
    <div className="step-main-container">
      <div className="step-content kit-room-import">
        <h1 className="step-title">
          Would you like to start with one a build created by one of our most
          successful growers?
        </h1>
        <div className="flex-all">
          <div className="two-group-wrapper">
            <Button text="Select from below" onClick={props.onDismiss} />
            <Button
              text="No, I’m gonna start my own"
              onClick={props.onDismiss}
            />
          </div>
        </div>
        <Gallery images={IMAGES} loadMore={loadMoreImages} />
      </div>
    </div>
  );
}
