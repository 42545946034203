// @flow
import * as React from "react";
import "./Header.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router-dom";

import floraImg from "./../../../../assets/image/Logo_Flora-White.png";

import { ReactComponent as LeftBackIcon } from "./../../../../assets/svg/left-back.svg";

function Header(props) {
  return (
    <header className="header">
      <div className="header-content">
        <div className="back-button" onClick={props.history.goBack}>
          <div>
            <LeftBackIcon color="white" />
          </div>
        </div>
        <div className="header-logo">
          <img src={floraImg} />
        </div>
        <div className="flex-start">
          <div className="header-text">ROOM BUILDER</div>
        </div>
        <div className="flex-end p-right-50 header-right cursor-pointer">
          <div className="header-text">MY ACCOUNT</div>
        </div>
      </div>
    </header>
  );
}

export default withRouter(Header);
