// @flow
import * as React from "react";
import PortalContainer from "./../PortalContainer/PortalContainer";

export default function LayoutSplashScreen() {
  return (
    <PortalContainer>
      <div></div>
    </PortalContainer>
  );
}
