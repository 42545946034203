// @flow
import * as React from "react";

import "./NumberSelect.scss";

export default function NumberSelect(props) {
  const value = Number(props.value) || 0;
  return (
    <div className="number-selection">
      <div className="number-selection-inner">
        <div
          onClick={(event) => {
            event.stopPropagation();
            props.onChange(value - 1);
          }}
          className="number-selection-control select-none"
        >
          <span>-</span>
        </div>
        <div className="number-selection-value">
          <span>{String(value)}</span>
        </div>
        <div
          onClick={(event) => {
            event.stopPropagation();
            props.onChange(value + 1);
          }}
          className="number-selection-control select-none"
        >
          <span>+</span>
        </div>
      </div>
    </div>
  );
}
