import * as React from "react";
import "./KitBuilder.scss";

import PlantContainer from "../../components/PlantContainer/PlantContainer";
import NumberSelect from "./../../components/NumberSelect/NumberSelect";
import Dropdown from "../../components/Dropdown/Dropdown";

export default function KitIrrigation(props) {
  return (
    <div className="step-main-container kit-irrigation">
      <div className="step-content">
        <h1 className="step-title">
          What type of system would you like to grow with?
        </h1>
        <div className="kit-container">
          {props.irrigationItems.map((item, index) => (
            <PlantContainer title={item.title} subTitle={item.subTitle}>
                <Dropdown displayValue="Pot Diameter" />
              <NumberSelect
                value={item.number}
                onChange={(number) => {
                  props.setIrrigationItems(
                    props.irrigationItems.map((_item, _index) =>
                      _index === index ? { ..._item, number } : _item
                    )
                  );
                }}
              />
              
            </PlantContainer>
          ))}
        </div>
      </div>
    </div>
  );
}
