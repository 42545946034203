import { asideKeys, iconKeys } from "./cartConstants";

export default {
  pages: {
    BOARD: {
      key: "BOARD",
    },
  },
  aside: {
    selected: undefined,
    items: {
      /**
       * onChange events and updates works better with objects than with arrays
       */
      [asideKeys.ROOM_AND_SYSTEM_STYLE]: {
        key: asideKeys.ROOM_AND_SYSTEM_STYLE,
        title: "ROOM & SYSTEM STYLE",
        icon: iconKeys.ROOM,
        data: {
          roomLength: 1,
          roomWidth: 1,
          plantsPerZone: 1,
          zoneNumber: 2,
          tierPerZoneNumber: 1,
          styles: [
            {
              key: "item1-style",
              label: "Style 1",
              value: "style1",
            },
            {
              key: "item2-style",
              label: "Style 2",
              value: "style2",
            },
            {
              key: "item3-style",
              label: "Style 3",
              value: "style3",
            },
            {
              key: "item4-style",
              label: "Style 4",
              value: "style4"
            },
            {
              key: "item5-style",
              label: "Style 5",
              value: "style5"
            },
          ],
          styleSelected: 0
        },
      },
      [asideKeys.COMPONENTS]: {
        key: asideKeys.COMPONENTS,
        title: "COMPONENTS",
        icon: iconKeys.COMPONENTS,
        data: {
          cartBuildLines: {}
        },
      },
      [asideKeys.NUTRIENTS]: {
        key: asideKeys.NUTRIENTS,
        title: "NUTRIENTS",
        icon: iconKeys.NUTRIENTS,
        data: {
          cartBuildLines: {}
        },
      },
    },
    footerItems: [
      {
        label: "PER PLANT",
        key: "PER PLANT-total-line-footer",
        amount: 5.49,
      },
      {
        label: "PER ZONE",
        key: "PER ZONE-total-line-footer",
        amount: 45.5,
      },
      {
        label: "PER SUBTOTAL",
        key: "PER SUBTOTAL-total-line-footer",
        amount: 100.5,
      },
    ],
  },
  footer: {
    checkoutAmount: 342.95
  }
};
