import { combineReducers } from "redux";

import * as auth from "./authReducer";
import * as  builder from "./builderReducer";
import * as  layout from "./layoutReducer";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  builder: builder.reducer,
  layout: layout.reducer
});
