// @flow
import * as React from "react";
import "./../KitBuilder.scss";

// reusable components import
import Button from "./../../../components/Button/Button";
import Input from "./../../../components/Input/Input";
import KitSelector from "./KitSelector";
import { Redirect, Route, Switch } from "react-router-dom";
import KitRoomImport from "./KitRoomImport";

function KitOptionsInput(props) {
  function onSubmit() {
    props.history.push("/kitbuilder/options/room");
  }
  return (
    <div className="step-main-container">
      <div className="step-content kit-option-input">
        <h1 className="step-title">
          How many plants would you like
          <br /> to cultivate?
        </h1>
        <Input
          type="number"
          onEnter={onSubmit}
          type="number"
          min="0"
          value={props.plantNumber}
          onChange={(event) => props.setPlantNumber(event.target.value)}
          placeholder="Enter # of plants"
        />
        <Button onClick={onSubmit} text="GO!" />
      </div>
    </div>
  );
}

export default function KitOptions(props) {
  const { state, setState, history } = props;
  return (
    <Switch>
      <Route
        path="/kitbuilder/options/plants"
        render={(routeProps) => (
          <KitOptionsInput
            {...routeProps}
            plantNumber={state.plantNumber}
            setPlantNumber={(plantNumber) =>
              setState({ ...state, plantNumber })
            }
          />
        )}
      />
      <Route
        path="/kitbuilder/options/selector"
        render={(routeProps) => (
          <KitSelector
            {...routeProps}
            plantNumber={state.plantNumber}
            setPlantKit={props.setPlantKit}
          />
        )}
      />
      <Route
        path="/kitbuilder/options/room"
        render={(routeProps) => (
          <KitRoomImport
            {...routeProps}
            onDismiss={() => {
              history.push("/kitbuilder/options/selector");
            }}
          />
        )}
      />
      <Route
        path="/kitbuilder/options"
        render={() => <Redirect to="/kitbuilder/options/plants" />}
      />
    </Switch>
  );
}
