// @flow
import * as React from "react";

import "./PlantWidget.scss";

import { ReactComponent as WeedIcon } from "./../../../assets/svg/weed.svg";

export default function PlantWidget(props) {
  const title = props.title ? (
    <p className="plant-kit-title"> {props.title} </p>
  ) : (
    <p className="plant-kit-title">
      {props.number} Plant
      <br />
      Kit
    </p>
  );
  const subTitle = props.subTitle ? (
    <p className="plant-kit-subtitle"> {props.subTitle} </p>
  ) : (
    <p className="plant-kit-subtitle">
      {props.number} {props.number === 1 ? "pot" : "pots"}
    </p>
  );

  return (
    <div
      className="plant-kit"
      onClick={() => props.onClick(props.value || props.number)}
    >
      <div className="weed-icon">
        <WeedIcon />
      </div>
      {title}
      {subTitle}
    </div>
  );
}
