
const initialState = {
};
const actionTypes = {
  RESET_CONFIG: "RESET_CONFIG"
};
export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.RESET_CONFIG: {
      return initialState;
    }
    default:
      return state;
  }
};
export const actions = {
  resetConfig: payload => ({ payload, type: actionTypes.RESET_CONFIG }),
};
