import * as React from "react";

// reusable components import
import PlantWidget from "./../../../components/PlantWidget/PlantWidget";

export default function KitSelector(props) {
  function submitKit(kitValue) {
    props.setPlantKit(kitValue);
    props.history.push("/kitbuilder/room");
  }

  return (
    <div className="step-main-container">
      {props.plantNumber <= 8 && (
        <div className="step-content">
          <h1 className="step-title">
            Since you are planning to grow fewer than 9 plants, we
            <br />
            recommend starting with a premade kit.
          </h1>
          <div className="kit-container">
            <PlantWidget number={4} onClick={submitKit} />
            <PlantWidget number={6} onClick={submitKit} />
            <PlantWidget number={8} onClick={submitKit} />
          </div>
        </div>
      )}
      {!(props.plantNumber <= 8) && (
        <div className="step-content">
          <h1 className="step-title">
            Since you are planning to grow more than 8 plants, you <br></br> can
            start with a premed kit or start a custom build.
          </h1>
          <div className="kit-container">
            <PlantWidget number={4} onClick={submitKit} />
            <PlantWidget number={6} onClick={submitKit} />
            <PlantWidget number={8} onClick={submitKit} />
            <PlantWidget
              title={
                <>
                  Custom
                  <br />
                  Build
                </>
              }
              value="custom"
              subTitle={"Build your own!"}
              onClick={submitKit}
            />
          </div>
        </div>
      )}
    </div>
  );
}
