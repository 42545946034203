// @flow
import * as React from "react";

import "./ImageGridList.scss";

class ImageCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { spans: 0 };
    this.imageRef = React.createRef();
  }

  componentDidMount() {
    this.imageRef.current.addEventListener("load", this.setSpans);
  }

  setSpans = () => {
    const height = this.imageRef.current.clientHeight;
    const spans = Math.ceil(height / 10);
    this.setState({ spans: spans });
  };

  render() {
    const { alt, src } = this.props.image;

    return (
      <div style={{ gridRowEnd: `span ${this.state.spans}` }}>
        <img ref={this.imageRef} src={src} alt={alt} />
      </div>
    );
  }
}

export default function ImageGridList(props) {
  const images = props.images.map((image) => {
    return <ImageCard key={image.id} image={image} />;
  });
  return (
    <div className="my-gallery-wrapper">
      <div className="image-list">{images}</div>
      {/* {props.loadMore && (
        <div className="gallery-footer">
          <div className="gallery-load" onClick={props.loadMore}>
            <div>Load more</div>
            <div>+</div>
          </div>
        </div>
      )} */}
    </div>
  );
}
