import * as React from "react";
import "./../KitBuilder.scss";

import { Redirect, Route, Switch } from "react-router-dom";
import KitRoomForm from "./KitRoomForm";
import KitRoomRetry from "./KitRoomRetry";
import KitRoomFormStepTwo from "./KitRoomFormStepTwo";

export default function KitRoom(props) {
  return (
    <Switch>
      <Route
        path="/kitbuilder/room/form"
        render={(routeProps) => (
          <KitRoomForm
            {...routeProps}
            roomSize={props.roomSize}
            plantKit={props.plantKit}
            setRoomSize={props.setRoomSize}
          />
        )}
      />
      <Route
        path="/kitbuilder/room/form-2"
        render={(routeProps) => (
          <KitRoomFormStepTwo
            {...routeProps}
            roomSize={props.roomSize}
            setRoomSize={props.setRoomSize}
          />
        )}
      />
      <Route
        path="/kitbuilder/room/retry"
        render={(routeProps) => (
          <KitRoomRetry
            {...routeProps}
            setPlantKit={props.setPlantKit}
            plantKit={props.plantKit}
          />
        )}
      />

      <Route
        path="/kitbuilder/room"
        render={() => <Redirect to="/kitbuilder/room/form" />}
      />
    </Switch>
  );
}
