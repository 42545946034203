// @flow
import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import objectPath from "object-path";

import WithNavigation from "./../../components/WithNavigation/WithNavigation";
import AsideCart from "./AsideCart/AsideCart";

import cartPageConfig from "./cartPageConfig";

import "./Cart.scss";
import CartBoard from "./CartBoard/CartBoard";
import { Component } from "react/cjs/react.production.min";
import { asideKeys, iconKeys } from "./cartConstants";

import { ReactComponent as PotasiumIconSvg } from "./../../../assets/svg/potasium.svg";
import { ReactComponent as CalciumIconSvg } from "./../../../assets/svg/calcium.svg";
import { ReactComponent as BariumIconSvg } from "./../../../assets/svg/barium.svg";
import { ReactComponent as ContainerIcon } from "./../../../assets/svg/container.svg";
import { ReactComponent as CartIcon } from "./../../../assets/svg/cart.svg";
import { CANVAS_TRIGGER_TYPES } from "../../utils/constants";

const dummyDataComponentBuildLines = [
  {
    key: "build-line-1604671091260-0",
    label: "POTPRO™ 6″ POT",
    price: 2.99,
    value: 1,
  },
  {
    key: "build-line-1604671091260-1",
    label: "POTPRO™ 6″ POT",
    price: 2.99,
    value: 1,
  },
  {
    key: "build-line-1604671091260-2",
    label: "POTPRO™ 6″ POT",
    price: 2.99,
    value: 1,
  },
  {
    key: "build-line-1604671091260-3",
    label: "POTPRO™ 6″ POT",
    price: 2.99,
    value: 1,
  },
  {
    key: "build-line-1604671091260-4",
    label: "POTPRO™ 6″ POT",
    price: 2.99,
    value: 1,
  },
  {
    key: "build-line-1604671091260-5",
    label: "POTPRO™ 6″ POT",
    price: 2.99,
    value: 1,
  },
  {
    key: "build-line-1604671091260-6",
    label: "POTPRO™ 6″ POT",
    price: 2.99,
    value: 1,
  },
  {
    key: "build-line-1604671091260-7",
    label: "POTPRO™ 6″ POT",
    price: 2.99,
    value: 1,
  },
  {
    key: "build-line-1604671091260-8",
    label: "POTPRO™ 6″ POT",
    price: 2.99,
    value: 1,
  },
  {
    key: "build-line-1604671091260-9",
    label: "POTPRO™ 6″ POT",
    price: 2.99,
    value: 1,
  },
  {
    key: "build-line-1604671091260-10",
    label: "POTPRO™ 6″ POT",
    price: 2.99,
    value: 1,
  },
  {
    key: "build-line-1604671091260-11",
    label: "POTPRO™ 6″ POT",
    price: 2.99,
    value: 1,
  },
  {
    key: "build-line-1604671091260-12",
    label: "POTPRO™ 6″ POT",
    price: 2.99,
    value: 1,
  },
  {
    key: "build-line-1604671091260-13",
    label: "POTPRO™ 6″ POT",
    price: 2.99,
    value: 1,
  },
  {
    key: "build-line-1604671091260-14",
    label: "POTPRO™ 6″ POT",
    price: 2.99,
    value: 1,
  },
  {
    key: "build-line-1604671091260-15",
    label: "POTPRO™ 6″ POT",
    price: 2.99,
    value: 1,
  },
  {
    key: "build-line-1604671091260-16",
    label: "POTPRO™ 6″ POT",
    price: 2.99,
    value: 1,
  },
  {
    key: "build-line-1604671091260-17",
    label: "POTPRO™ 6″ POT",
    price: 2.99,
    value: 1,
  },
  {
    key: "build-line-1604671091260-18",
    label: "POTPRO™ 6″ POT",
    price: 2.99,
    value: 1,
  },
  {
    key: "build-line-1604671091260-19",
    label: "POTPRO™ 6″ POT",
    price: 2.99,
    value: 1,
  },
];
const dummyDataNutrientsBuildLines = [
  {
    key: "build-line-1604fdsa633271091260-0",
    label: "POTPRO™ 6″ POT",
    price: 2.99,
    value: 1,
    icon: iconKeys.POTASIUM,
  },
  {
    key: "build-line-1604673fdsa2321091260-0",
    label: "POTPRO™ 6″ POT",
    price: 2.99,
    value: 1,
    icon: iconKeys.CALCIUM,
  },
  {
    key: "build-line-160467fds3321091260-0",
    label: "POTPRO™ 6″ POT",
    price: 2.99,
    value: 1,
    icon: iconKeys.BARIUM,
  },
  {
    key: "build-line-160fds43232671091260-0",
    label: "POTPRO™ 6″ POT",
    price: 2.99,
    value: 1,
    icon: iconKeys.POTASIUM,
  },
  {
    key: "build-line-16046fsdfds71332091260-0",
    label: "POTPRO™ 6″ POT",
    price: 2.99,
    value: 1,
    icon: iconKeys.CALCIUM,
  },
  {
    key: "build-line-1604fds67103223231260-0",
    label: "POTPRO™ 6″ POT",
    price: 2.99,
    value: 1,
    icon: iconKeys.BARIUM,
  },
  {
    key: "build-line-160467fdsa1fdfdsas0932234321260-0",
    label: "POTPRO™ 6″ POT",
    price: 2.99,
    value: 1,
    icon: iconKeys.BARIUM,
  },
];

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageConfig: cartPageConfig,
      cartBoardTriggers: {},
      cartBoardIsLoading: false,
    };
  }
  componentDidMount = () => {
    this.getComponentPageLines();
  };
  getComponentPageLines = () => {
    const { pageConfig } = this.state;

    objectPath.set(
      pageConfig,
      `aside.items.${asideKeys.COMPONENTS}.data.cartBuildLines`,
      dummyDataComponentBuildLines.reduce(
        (final, current) => ({
          ...final,
          [current.key]: { ...current, icon: <ContainerIcon /> },
        }),
        {}
      )
    );
    const nutrientsItems = dummyDataNutrientsBuildLines.reduce(
      (final, current) => {
        const icon = ((icon) => {
          let _icon = <CalciumIconSvg />;
          switch (icon) {
            case iconKeys.POTASIUM:
              _icon = <PotasiumIconSvg />;
              break;
            case iconKeys.BARIUM:
              _icon = <BariumIconSvg />;
              break;
            case iconKeys.CALCIUM:
              _icon = <CalciumIconSvg />;
              break;
          }
          return _icon;
        })(current.icon);

        return {
          ...final,
          [current.key]: {
            ...current,
            icon,
          },
        };
      },
      {}
    );

    objectPath.set(
      pageConfig,
      `aside.items.${asideKeys.NUTRIENTS}.data.cartBuildLines`,
      nutrientsItems
    );
    this.setState({ pageConfig });
  };
  setCurrentStep = (currentStep) => {
    this.setState({ currentStep });
  };

  onSelectAsideMenu = (item) => {
    const { pageConfig } = this.state;
    pageConfig.aside.selected = item
      ? {
          key: item.key,
          title: item.title,
        }
      : undefined;
    this.setState({
      pageConfig,
    });
  };

  onChangeAsideItem = (property, value, key) => {
    if (this.state.cartBoardIsLoading) {
      return;
    }
    const { pageConfig } = this.state;
    objectPath.set(pageConfig.aside.items[key], property, value);
    this.setState({ pageConfig });
  };
  setTriggerCartBoard = (type) => {
    if (this.state.cartBoardIsLoading) {
      return;
    }
    this.setState({
      cartBoardTriggers: {
        type,
        key: Date.now(),
      },
    });
  };
  triggerCartEvent = (type) => {
    if (this.state.cartBoardIsLoading) {
      return;
    }
    const event = new Event("custom-event-homeview");
    event.data = { type };
    window.dispatchEvent(event);
  };
  refreshCartBoard = () => {
    if (this.state.cartBoardIsLoading) {
      return;
    }
    this.setState({
      cartBoardKey: Date.now(),
    });
  };
  render() {
    return (
      <div className="cart-page">
        {this.state.pageConfig.aside && (
          <AsideCart
            {...this.state.pageConfig.aside}
            onSelectAsideMenu={this.onSelectAsideMenu}
            onChangeAsideItem={this.onChangeAsideItem}
            onSetRoom2dKey={() => {
              this.setState({
                room2dKey: Date.now() + "update"
              })
            }}
          />
        )}
        <Switch>
          <Route
            path="/cart/board"
            render={(props) => (
              <WithNavigation
                history={props.history}
                onChangeCurrentPath={(value) => this.setCurrentStep(value)}
                value={cartPageConfig.pages.BOARD.key}
              >
                <CartBoard
                  isLoading={this.state.cartBoardIsLoading}
                  setCartBoardLoading={(value) => {
                    // this.setState({ cartBoardIsLoading: value });
                  }}
                  key={`CartBoard-${this.state.cartBoardKey || 1}`}
                  {...props}
                  trigger={this.state.cartBoardTriggers}
                  config2D={{
                    ...this.state.pageConfig.aside.items[
                      asideKeys.ROOM_AND_SYSTEM_STYLE
                    ].data,
                  }}
                  room2dKey={this.state.room2dKey}
                />
              </WithNavigation>
            )}
          />
          <Route path="/cart" render={() => <Redirect to="/cart/board" />} />
        </Switch>
        <div className="cart-page-footer">
          <div
            className={`cart-page-footer-inner ${
              this.state.cartBoardIsLoading ? " cart-board-is-loading" : ""
            }`}
          >
            <div
              className="cart-footer-act-icon"
              // onClick={() => this.toggleCardBoard2D()}
            >
              <div className="frame-zoom-icon"></div>
            </div>
            <div
              className="cart-footer-act-icon"
              onClick={() =>
                this.setTriggerCartBoard(CANVAS_TRIGGER_TYPES.ZOOM_IN)
              }
            >
              <div className="zoom-in-icon"></div>
            </div>
            <div
              className="cart-footer-act-icon"
              onClick={() =>
                this.setTriggerCartBoard(CANVAS_TRIGGER_TYPES.ZOOM_OUT)
              }
            >
              <div className="zoom-out-icon"></div>
            </div>
            <div
              className="cart-footer-act-icon"
              onClick={() =>
                this.triggerCartEvent(CANVAS_TRIGGER_TYPES.FULLSCREEN)
              }
            >
              <div className="fullscreen-icon"></div>
            </div>
            <div
              className="cart-footer-act-icon"
              onClick={() => this.refreshCartBoard()}
            >
              <div className="refresh-icon" />
            </div>
            <div className="cart-footer-checkout-save cursor-pointer">
              <div className="checkout-text">SAVE</div>
            </div>
            <div className="cart-footer-checkout cursor-pointer">
              <div className="checkout-icon">
                <CartIcon />
              </div>
              <div className="checkout-text">CHECKOUT</div>
              <div className="checkout-amount">
                ${this.state.pageConfig.footer.checkoutAmount}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Cart;
