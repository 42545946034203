// @flow
import * as React from "react";
import { CANVAS_TRIGGER_TYPES } from "../../../utils/constants";

import "./CartBoard.scss";
import Room2D from "./Room2D";

import { canvasDim } from "./../../../utils/constants";
import PairSelctor from "../../../components/PairSelctor/PairSelctor";
import Room2dCanvas from "./Room2dCanvas";

let timer = 0;
let controllerInstance = null;

/**
 * @param {object} props Component props
 * @param {string | undefined} props.triggers.key
 * @param {string | undefined} props.triggers.type - from constants
 */
export default function CartBoard(props) {
  const [_canvasState, _setCanvasState] = React.useState({
    progressValue: 0,
    percentage: 0,
  });

  const [canvas2D, setConvas2d] = React.useState({
    zoomKey: "",
    zoomDir: 1
  });
  const [canvasSize, setCanvasSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const pageContainerRef = React.useRef();

  const canvasStateRef = React.useRef(_canvasState);

  const [state, setState] = React.useState({
    active3D: false,
  });

  function setCanvasState(newCanvasState) {
    _setCanvasState(newCanvasState);
    canvasStateRef.current = newCanvasState;
  }

  function zoomIn(value) {
    if(state.active3D) {
      if (controllerInstance) {
        const l = controllerInstance.getController();
        l.moveCamera(value);
      }
    } else {
      setConvas2d({
        ...canvas2D,
        zoomDir: value,
        zoomKey: Date.now()
      })
    }
  }

  function initCanvas() {
    const homeUrl = 
    window.location.origin + "/default.sh3d";

    const onerror = function (err) {
      if (err == "No WebGL") {
        console.log("Sorry, your browser doesn't support WebGL.");
      } else {
        console.log(err.stack);
        console.log(
          "Error: " +
            (err.message ? err.constructor.name + " " + err.message : err) +
            (window.location.href.indexOf("file://") === 0
              ? "\nCheck your browser is allowed to access local files."
              : "")
        );
      }
    };
    const onprogression = function (part, info, percentage) {
      let progressValue = canvasStateRef.current.progressValue;

      if (part === window.HomeRecorder.READING_HOME) {
        // Home loading is finished
        progressValue = percentage * 100;
        info = info.substring(info.lastIndexOf("/") + 1);
      } else if (part === window.Node3D.READING_MODEL) {
        // Models loading is finished
        progressValue = 100 + percentage * 100;
      }
      const percentageText =
        (percentage ? Math.floor(percentage * 100) + "% " : "") +
        part +
        " " +
        info;
      clearTimeout(timer);
      timer = setTimeout(() => {
        setCanvasState({
          ...canvasStateRef.current,
          percentage,
          percentageText,
          progressValue,
        });
      }, 100);
    };
    // Display home in canvas 3D
    // Mouse and keyboard navigation explained at
    // http://sweethome3d.cvs.sf.net/viewvc/sweethome3d/SweetHome3D/src/com/eteks/sweethome3d/viewcontroller/resources/help/en/editing3DView.html
    // You may also switch between aerial view and virtual visit with the space bar
    // For browser compatibility, see http://caniuse.com/webgl
    controllerInstance = window.viewHome(
      "viewerCanvas", // Id of the canvas
      homeUrl, // URL or relative URL of the home to display
      onerror, // Callback called in case of error
      onprogression, // Callback called while loading
      {
        roundsPerMinute: 0, // Rotation speed of the animation launched once home is loaded in rounds per minute, no animation if missing or equal to 0
        navigationPanel: "none", // Displayed navigation arrows, "none" or "default" for default one or an HTML string containing elements with data-simulated-key
        // attribute set "UP", "DOWN", "LEFT", "RIGHT"... to replace the default navigation panel, "none" if missing
        aerialViewButtonId: "aerialView", // Id of the aerial view radio button, radio buttons hidden if missing
        virtualVisitButtonId: "virtualVisit", // Id of the aerial view radio button, radio buttons hidden if missing
        levelsAndCamerasListId: "levelsAndCameras", // Id of the levels and cameras select component, hidden if missing // Uncomment to select the displayed level, default level if missing */ // Uncomment to choose the list of displayed levels, no select component if empty array */ // Uncomment to select a camera, default camera if missing */ // Uncomment to choose the list of displayed cameras, no camera if missing */
        /* level: "Roof", */ /* selectableLevels: ["Ground floor", "Roof"], */ /* camera: "Exterior view", */ /* selectableCameras: ["Exterior view", "Kitchen"], */ activateCameraSwitchKey: true, // Switch between top view / virtual visit with space bar if not false or missing */
      }
    );
    window.controllerInstance = controllerInstance;
  }

  function toggleCardBoard2D() {
    setState({
      ...state,
      active3D: !state.active3D,
    });
  }

  React.useEffect(() => {
    props.setCartBoardLoading(true);
    clearTimeout(timer);
    timer = setTimeout(() => {
      initCanvas();
    }, 300);
    return () => {
      clearTimeout(timer);
      if (controllerInstance) {
        controllerInstance.dispose();
      }
      props.setCartBoardLoading(false);
    };
  }, []);

  React.useEffect(() => {
    if (props.trigger.key && Date.now() < props.trigger.key + 500) {
      switch (props.trigger.type) {
        case CANVAS_TRIGGER_TYPES.ZOOM_IN:
          zoomIn(100);
          break;
        case CANVAS_TRIGGER_TYPES.ZOOM_OUT:
          zoomIn(-100);
          break;
      }
    }
  }, [props.trigger]);

  React.useEffect(() => {
    if (_canvasState.percentage === 1) {
      props.setCartBoardLoading(false);
    } else {
      if (!props.isLoading) {
        props.setCartBoardLoading(true);
      }
    }
  }, [_canvasState.percentage]);

  function updateSize() {
    setCanvasSize({
      width: pageContainerRef.current ? pageContainerRef.current.clientWidth - 155 : 200,
      height: pageContainerRef.current ? pageContainerRef.current.clientHeight - 155  : 200,
    })
  }
  React.useEffect(() => {
    updateSize();
    window.addEventListener("resize", updateSize);
    return () => {
      window.removeEventListener("resize", updateSize);
    }
  }, [])

  return (
    <div className="cart-board is-cart-page" ref={pageContainerRef}>
      <div className="room-switcher">
        <PairSelctor
          onChange={toggleCardBoard2D}
          items={[{ label: "2D" }, { label: "3D" }]}
          selected={Number(state.active3D)}
        />
      </div>
      <div
        className="position-relative"
        style={state.active3D ? {} : { display: "none" }}
      >
        <canvas
          id="viewerCanvas"
          className="viewerComponent"
          width={canvasDim.width}
          height={canvasDim.height}
          style={{
            backgroundColor: "#CCCCCC",
            border: "1px solid gray",
            outline: "none",
            touchAction: "none",
          }}
          tabIndex={1}
        />
        {props.isLoading && (
          <div
            className="flex-all"
            style={{
              width: canvasDim.width,
              height: canvasDim.height,
              position: "absolute",
              display: "flex",
              top: "0",
              left: "0",
            }}
          >
            <div
              id="viewerProgressDiv"
              style={{
                width: "450px",
                backgroundColor: "rgba(128, 128, 128, 0.7)",
                padding: "20px",
                borderRadius: "25px",
              }}
            >
              <progress
                id="viewerProgress"
                className="viewerComponent"
                value={_canvasState.progressValue}
                max={200}
                style={{ width: "400px" }}
              />
              <label
                id="viewerProgressLabel"
                className="viewerComponent"
                value={_canvasState.text}
                style={{
                  marginTop: "2px",
                  display: "block",
                  marginLeft: "10px",
                }}
              />
            </div>
          </div>
        )}
      </div>
      {/* this part temporarily stays here hidden (because of DOM selection made in 3droom scripts) */}
      <div
        id="viewerNavigationDiv"
        style={{ display: "none", marginTop: "-60px" }}
      >
        <input
          id="aerialView"
          className="viewerComponent"
          name="cameraType"
          type="radio"
          style={{ visibility: "hidden" }}
        />
        <label
          className="viewerComponent"
          htmlFor="aerialView"
          style={{ visibility: "hidden" }}
        >
          Aerial view
        </label>
        <input
          id="virtualVisit"
          className="viewerComponent"
          name="cameraType"
          type="radio"
          style={{ visibility: "hidden" }}
        />
        <label
          className="viewerComponent"
          htmlFor="virtualVisit"
          style={{ visibility: "hidden" }}
        >
          Virtual visit
        </label>
        <select
          id="levelsAndCameras"
          className="viewerComponent"
          style={{ visibility: "hidden" }}
        />
      </div>

      <div
        className="cart-board-2d"
        style={!state.active3D ? {} : { display: "none" }}
      >
        {/* <Room2D {...props.config2D} key={props.room2dKey}/> */}
        <Room2dCanvas canvasSize={canvasSize} zoomDir={canvas2D.zoomDir} zoomKey={canvas2D.zoomKey}/>
      </div>
    </div>
  );
}
