// @flow
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Cart from './pages/Cart/Cart';
import { KitBuilder } from './pages/KitBuilder/KitBuilder';

export default function Basepage(props) {
  return (
    <Switch>
      <Route path="/kitbuilder" component={KitBuilder} />
      <Route path="/cart" component={Cart} />
      <Redirect exact from="/" to="/kitbuilder" />
    </Switch>
  );
};