// @flow
import * as React from "react";

import "./PlantContainer.scss";

import { ReactComponent as PlantVesselIcon } from "./../../../assets/svg/plant-vessel.svg";
import { QuestionMarkWidget } from "../QuestionMarkWidget/QuestionMarkWidget";

export default function PlantContainer(props) {
  return (
    <div
      className={"plant-container" + (props.onClick ? " cursor-pointer" : "")}
      onClick={props.onClick ? props.onClick : () => {}}
    >
      <QuestionMarkWidget onClick={() => {}} />
      <div className="plant-vessel-icon">
        <PlantVesselIcon />
      </div>
      {props.title && <p className="plant-container-title">{props.title}</p>}
      {props.subTitle && <p className="plant-kit-subtitle">{props.subTitle}</p>}
      {props.children}
    </div>
  );
}
