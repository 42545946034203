// @flow
import * as React from "react";

import { ReactComponent as CheckCircleIcon } from "./../../../assets/svg/circle-check.svg";
import { ReactComponent as CheckDownCircleIcon } from "./../../../assets/svg/circle-down.svg";
import { ReactComponent as CheckRightCircleIcon } from "./../../../assets/svg/circle-right.svg";

import "./NavigationMenu.scss";

export function NavigationMenu(props) {
  return (
    <div className="nav-sub-menu">
      {props.steps.map((item, index) => {
        let _class = "";
        let _filledClass = "";
        if (index === props.selectedIndex) {
          _class += " is-selected";
        }
        if (index < props.selectedIndex) {
          _filledClass += " icon-filled";
        }
        return (
          <div key={item.key} className={`nav-item${_class}`}>
            <div className="nav-sub-item">
              <div className="nav-sub">
                <div className="nav-step">Step {index + 1}</div>
                <div className="nav-label">{item.label}</div>
              </div>
              <div className={`nav-icon${_filledClass}`}>
                {index === props.steps.length - 1 ? (
                  <CheckCircleIcon />
                ) : index <= props.selectedIndex ? (
                  <CheckDownCircleIcon />
                ) : (
                  <CheckRightCircleIcon />
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
