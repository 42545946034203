import * as React from "react";
import "./Button.scss";

export default function Button(props) {
  return (
    <button
      onClick={typeof props.onClick === "function" ? props.onClick : () => {}}
      className={
        `custom-button` +
        (props.variant ? " " + props.variant : "") +
        (props.className ? " " + props.className : "")
      }
    >
      {props.text}
    </button>
  );
}
