import * as React from "react";
import "./../KitBuilder.scss";

import { Redirect, Route, Switch } from "react-router-dom";
import PlantContainer from "../../../components/PlantContainer/PlantContainer";
import NumberSelect from "./../../../components/NumberSelect/NumberSelect";

function KitContentContainer(props) {
  return (
    <div className="step-main-container kit-content">
      <div className="step-content">
        <h1 className="step-title">
          Do you already have containers for your plants?
        </h1>
        <div className="kit-container position-relative">
          <PlantContainer
            title="Yes"
            onClick={() => {
              props.history.push("/kitbuilder/irrigation");
            }}
          />
          <PlantContainer title="No" subTitle="I'm going to use">
            <NumberSelect
              value={props.value}
              onChange={props.setPlantContainerValue}
            />
          </PlantContainer>
        </div>
      </div>
    </div>
  );
}

export default function KitContent(props) {
  return (
    <Switch>
      <Route
        path="/kitbuilder/content/container"
        render={(routeProps) => (
          <KitContentContainer
            {...routeProps}
            value={props.plantContainerValue}
            setPlantContainerValue={props.setPlantContainerValue}
          />
        )}
      />
      <Route
        path="/kitbuilder/content"
        render={() => <Redirect to="/kitbuilder/content/container" />}
      />
    </Switch>
  );
}
